<template>

    <div class="container mx-auto px-4">

        <Breadcrumb :history="breadcrumbs || []" :title="data.title || 'You are here'" :crumbs-visible="breadcrumb.crumbsVisible" :show-current="breadcrumb.showCurrent" :more-text="breadcrumb.moreText"></Breadcrumb>

        <template v-if="history.length">

            <template v-for="(card, key) in history">

                <div v-if="card.load_data" ref="history-cards" :key="key" class="mb-16">

                    <!-- Previous button -->
                    <div v-if="key > 0" class="text-center pt-2">
                        <button class="text-center text-telkom-blue-light" @click="scrollToPrevious(key)">
                            <i class="fa fa-chevron-up"></i>
                            <div class="text-xs">previous</div>
                        </button>
                    </div>

                    <div class="flex items-start">

                        <div class="flex-1 bg-white border border-gray-300 mt-6">

                            <!-- Show appropriate card -->
                            <component class="p-8" :is="getComponent(card.load_data.state)" :data="card.load_data" @applyAction="applyAction" />

                            <!-- You chose: xxx -->
                            <template v-if="card.load_data.choices.length">

                                <div class="flex flex-wrap items-start bg-telkom-blue-lightest px-6 pt-3 pb-6">

                                    <div class="pt-3 flex-auto flex flex-wrap">

                                        <div class="font-telkom-bold px-1 whitespace-no-wrap">YOU CHOSE:</div>

                                        <div class="flex-auto px-1">
                                            <div v-for="(choice, key) in card.load_data.choices" :key="key">
                                                <div>{{ choice.text || 'Continue' }}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="pt-3 px-1">
                                        <timeago :datetime="card.datetime" :auto-update="60" class="text-xs"></timeago>
                                    </div>

                                </div>

                            </template>

                        </div>
                    </div>
                </div>
            </template>

        </template>

        <template v-if="data.state">

            <div ref="latest-state-card" :style="`min-height:calc(100vh - (115px * ${ history.length ? 1 : 2 }) );`">

                <div v-if="history.length" class="text-center pt-2">
                    <button class="text-center text-telkom-blue-light" @click="scrollToPrevious(0)">
                        <i class="fa fa-chevron-up"></i>
                        <div class="text-xs">previous</div>
                    </button>
                </div>

                <div class="pt-4 pb-16 flex items-start">

                    <div class="flex-1 p-8 bg-white border border-gray-300">

                        <component :is="getComponent(data.state)" :data="data" @applyAction="applyAction" />

                    </div>

                </div>

            </div>

        </template>

    </div>

</template>

<script>

import localStore from '../localStore'

import Breadcrumb from '@/components/Breadcrumb.vue'
import VaStep     from '@/components/VaStep.vue'
import VaDecision from '@/components/VaDecision.vue'
import VaRecord   from '@/components/VaRecord.vue'
import VaRecords  from '@/components/VaRecords.vue'
import VaSummary  from '@/components/VaSummary.vue'
import Va404      from '@/components/Va404.vue'

import { mapState, mapActions } from 'vuex'

export default {

    name: 'VaState',

    components: {
        Breadcrumb,
        VaStep,
        VaDecision,
        VaRecord,
        VaRecords,
        VaSummary,
        Va404
    },

    data () {

        return {

            breadcrumb: {
                crumbsVisible: 0,
                showCurrent: false,
                moreText: '...'
            }

        }
    },

    computed: {

        ...mapState(['vastate', 'data']),

        breadcrumbs () {

            let original = this.data.history || [];

            let history = original.slice().reverse();

            return history;
        },

        history () {

            let history = this.data.history || [];

            return history.map( each => {

                let action_data = each['action_data'] || {};
                let action = action_data.action || {};
                let chosen_actionid = action.actionid || false;

                if ( !chosen_actionid ) { return false; }

                let load_data = each['load_data'] || {};
                let thestate = `${load_data.state}|${load_data.substate}`;
                let choices = [];
                let choice = {};
                let answer = {};
                let actions = {};

                switch (thestate) {

                    case 'step|step':
                    case 'record|record':

                        choice = load_data.action || {};
                        choice['chosen'] = true;
                        choices.push(choice);

                    break;

                    case 'decision|decision':
                    case 'decision|finder_question_ask':

                        if (load_data.answers) {

                            answer = {};

                            load_data.answers.map( choice => {
                                answer['action'] = choice.action || {};
                                answer['actionid'] = answer['action'].actionid || false;
                                if (answer['actionid'] === chosen_actionid) {
                                    choice['chosen'] = true;
                                    choices.push(choice);
                                }
                            });
                        }

                    break;

                    case 'decision|finder_question_choose':

                        load_data.answers.map( question => {

                            answer = {};
                            actions = question.actions || {};

                            answer['action'] = actions['finder_question'] || {};
                            answer['actionid'] = answer['action'].actionid || false;
                            if (answer['actionid'] === chosen_actionid) {
                                question['chosen'] = true;
                                choices.push(question);
                            }

                            question.answers.map( choice => {

                                answer = {};

                                answer['action'] = choice.action || {};
                                answer['actionid'] = answer['action'].actionid || false;
                                if (answer['actionid'] === chosen_actionid) {
                                    choice['chosen'] = true;
                                    choices.push(choice);
                                }
                            });
                        });

                    break;
                }

                each['load_data']['choices'] = choices;

                return each;
            });
        }

    },

    beforeRouteEnter (to, from, next) {

        let stateid = localStore.getStateid(to.params.guid || '');

        if ( !stateid ) { next({ path: '/notfound', replace: true }); return; }

        next(_this => { _this.loadState(stateid); });
    },

    beforeRouteUpdate (to, from, next) {

        let stateid = localStore.getStateid(to.params.guid || '');

        if ( !stateid ) { next({ path: '/notfound', replace: true }); return; }

        this.loadState(stateid);

        next();
    },

    updated () {

        // Prevent button focus border from showing on the next card
        document.activeElement.blur();

        setTimeout( () => { this.scrollToLatest(); }, 500);
    },

    watch: {

        data(data) {

            data['stateid'] = parseInt(this.stateid, 10) || 0;

            this.setData(this.stateid, data);
        },

        '$screen.width': {

            immediate: true,

            handler() {

                const screenWidth = this.$screen.width;

                this.breadcrumb.showCurrent = false;
                this.breadcrumb.crumbsVisible = 0;
                this.breadcrumb.moreText = 'Back to ...';

                if (screenWidth >= 400) {
                    this.breadcrumb.moreText = '';
                    this.breadcrumb.showCurrent = true;
                }

                if (screenWidth >= 450) {
                    this.breadcrumb.crumbsVisible = 1;
                }

                if (this.breadcrumbs.length === 0) {
                    this.breadcrumb.showCurrent = true;
                }
            }
        }

    },

    methods: {

        ...mapActions(['loadState', 'applyAction']),

        getComponent (state) {

            let map = {
                'step'       : 'VaStep',
                'decision'   : 'VaDecision',
                'record'     : 'VaRecord',
                'records'    : 'VaRecords',
                'summary'    : 'VaSummary'
            };

            return map[state] || 'Va404';
        },

        setData (stateid, data) {

            let title = data.title ? `| ${data.title}` : '';

            document.title = `Telkom ${title}`;
        },

        scrollToPrevious (key) {

            let cards = this.$refs['history-cards'];

            let idx = (key ? key : cards.length) - 1;

            let el = cards[idx];

            window.scrollTo({ top: this.getElTop(el), behavior: 'smooth' });
        },

        getElTop (el) {

            let rect = el.getBoundingClientRect();

            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            return rect.top + scrollTop;
        },

        scrollToLatest () {

            if ( !this.history.length ) { return false; }

            this.$nextTick( () => {

                let el = this.$refs['latest-state-card'] || false;

                if ( !el ) { return false; }

                window.scrollTo({ top: this.getElTop(el), behavior: 'smooth' });
            });
        }

    }

}
</script>
